body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.report {
  border-collapse: collapse;
  width: 100%;
}

table.report th, table.report td {
  border:1px solid #9e9e9e;
  padding: 4px;
  text-align: center;
}

table.obixtable {
  border-collapse: collapse;
  width: 210px;
  float: left;
}

table.obixtable th, table.obixtable td {
  border:1px solid #9e9e9e;
  font-size: 1em;
}

table.obixtable .err {
  color: #FF6D00;
}

table.obixtable td.alarm {
  color: #FF6D00;
}
table.obixtable .alarm-header {
  background-color: gold;
}

.changed {
  background-color: #2E7D32;
}

.fault {
  color: #cf3008;
}

.warning {
  color: #ffae00;
}

/* Devices의 헤더 */
.p-card.grayheader .p-card-title {
  background-color: var(--teal-200);
}

.p-panel.grayheader .p-panel-header {
  background-color: var(--teal-200);
}
/* Device panel 상단 공백 제거 */
.p-panel .p-panel-content {
  padding-top: 0.1em;
}

.layout-wrapper .layout-main .layout-content  {
  padding: 1.5rem;
}

.svg-normal {
  fill: var(--text-color);
  stroke: var(--text-color);
}

.svg-secondary {
  fill: var(--text-color-secondary);
  stroke: var(--text-color-secondary);
}

.svg-gray {
  fill: var(--surface-600);
  stroke: var(--surface-600);
}

.svg-green {
  fill: #4CAF50;
  stroke: #4CAF50;
}

.svg-gray-light {
  fill: #424242;
  stroke: #424242;
}

.svg-gray-dark {
  fill: #b4b0b0;
  stroke: #b4b0b0;
}

.svg-red {    
  /*fill: #e91e63;
  stroke: #e91e63;*/
  fill: #E53935    ;
  stroke: #E53935    ;
  /*stroke-width: 20;*/
}
